import PATH from 'path.config'

export default async function getMenuData() {
  return [
    {
      category: true,
      title: 'Dashboards',
    },
    {
      title: '홈',
      key: 'home',
      icon: 'fe fe-home',
      url: PATH.ADMIN.HOME,
    },
    {
      title: '접속 현황',
      key: 'dashboard',
      icon: 'fe fe-pie-chart',
      url: PATH.ADMIN.DASHBOARD,
    },

    {
      title: '프로젝트 스케쥴',
      key: 'schedule',
      icon: 'fe fe-calendar',
      url: PATH.ADMIN.SCHEDULE,
    },

    {
      title: '라이브',
      key: 'live_program',
      // url: PATH.GUIDE,
      items: [
        {
          title: '방송 콘트롤',
          key: 'controlbox',
          icon: 'fe fe-sliders',
          url: PATH.ADMIN.CONTROLBOX,
        },

        {
          title: '라이브 진행',
          key: 'progress',
          icon: 'fe fe-play font-weight-bold',
          url: PATH.ADMIN.DIRECTOR,
        },
        {
          title: '채팅',
          key: 'chat',
          icon: 'fe fe-message-circle font-weight-bold',
          url: PATH.ADMIN.CHAT,
        },
        {
          title: '온라인 로그 관리',
          key: 'view_report',
          icon: 'fe fe-trending-up font-weight-bold',
          url: PATH.ADMIN.LOG_REPORT,
        },
        // {
        //   title: '실시간설문 진행',
        //   key: 'live-survey',
        //   icon: 'fe fe-check-circle font-weight-bold',
        //   url: PATH.ADMIN.LIVE_SURVEY,
        // },
      ],
    },

    {
      title: '라이브 컨텐츠 관리',
      key: 'contents',
      // url: PATH.GUIDE,
      items: [
        {
          category: true,
          title: '라이브 스트리밍 / VOD',
        },
        // {
        //   title: '카테고리 관리',
        //   key: 'category',
        //   icon: 'fe fe-list',
        //   url: PATH.ADMIN.CATEGORY,
        // },

        {
          title: '프로그램 관리',
          key: 'live',
          icon: 'fe fe-list',
          url: PATH.ADMIN.LIVE,
        },
        {
          title: 'VOD 관리',
          key: 'vod',
          icon: 'fe fe-youtube',
          url: PATH.ADMIN.VOD,
        },
        // {
        //   category: true,
        //   title: '시험/설문',
        // },
        // {
        //   title: '설문조사',
        //   key: 'survey_list',
        //   icon: 'fe fe-file-minus font-weight-bold',
        //   url: PATH.ADMIN.SURVEY_LIST,
        // },
        // {
        //   title: '시험',
        //   key: 'exam_list',
        //   icon: 'fe fe-map font-weight-bold',
        //   url: PATH.ADMIN.EXAM_LIST,
        // },
        {
          title: '방송 채널',
          key: 'channel',
          icon: 'fe fe-git-commit font-weight-bold',
          url: PATH.ADMIN.CHANNEL,
        },
      ],
    },
    {
      title: '회원 관리',
      key: 'user_management',
      // url: PATH.GUIDE,
      items: [
        {
          title: '회원목록',
          key: 'users',
          icon: 'fe fe-users font-weight-bold',
          url: PATH.ADMIN.USERS,
        },
        {
          title: '회원 가입 항목',
          key: 'user_profile',
          icon: 'fe fe-user-plus font-weight-bold',
          url: PATH.ADMIN.PROFILE,
        },

        // {
        //   title: '티켓 관리',
        //   key: 'ticket',
        //   icon: 'fe fe-link  font-weight-bold',
        //   url: PATH.ADMIN.TICKET,
        // },
        {
          title: '회원 권한 설정',
          key: 'project_user',
          icon: 'fe fe-user-check font-weight-bold',
          url: PATH.ADMIN.SITE_USER_AUTH,
        },
        {
          title: '가입 환영 이메일 설정',
          key: 'project_welcome_mail',
          icon: 'fe fe-mail font-weight-bold',
          url: PATH.ADMIN.SITE_WELCOME_MAIL,
        },
        {
          title: '가입시 동의 문구',
          key: 'user-signup-agreements',
          icon: 'fe fe-check-square font-weight-bold',
          url: PATH.ADMIN.AGREEMENTS,
        },
        // {
        //   title: '네이버 로그인 설정',
        //   key: 'user-naver-login',
        //   icon: 'fe fe-unlock font-weight-bold',
        //   url: PATH.ADMIN.NAVER_LOGIN,
        // },
        // {
        //   title: '회원 일괄 등록',
        //   key: 'user-batch-register',
        //   icon: 'fe fe-plus font-weight-bold',
        //   url: PATH.ADMIN.USER_BATCH_REGISTER,
        // },
      ],
    },
    {
      title: '페이지 컨텐츠 관리',
      key: 'pages',
      // url: PATH.GUIDE,
      items: [
        {
          title: '페이지 공통 설정',
          key: 'project_settings',
          url: PATH.ADMIN.SITE_SETTINGS,
        },
        {
          title: '메뉴 관리',
          key: 'menu-setting',
          icon: 'fe fe-sidebar font-weight-bold',
          url: PATH.ADMIN.MENU,
        },
        {
          title: '첫화면',
          key: 'welcome',
          icon: 'fe fe-home',
          url: PATH.ADMIN.WELCOME,
        },
        // {
        //   title: '인사말',
        //   key: 'about',
        //   icon: 'fe fe-pocket',
        //   url: PATH.ADMIN.ABOUT,
        // },
        // {
        //   title: '연자 소개',
        //   key: 'speaker',
        //   icon: 'fe fe-award',
        //   url: PATH.ADMIN.SPEAKER,
        // },
        // {
        //   title: '스폰서',
        //   key: 'sponsors',
        //   icon: 'fe fe-wind font-weight-bold',
        //   url: PATH.ADMIN.SPONSOR,
        // },
        {
          title: '이용안내',
          key: 'guide',
          icon: 'fe fe-corner-right-up font-weight-bold',
          url: PATH.ADMIN.GUIDE,
        },
        {
          title: '가입 신청 안내',
          key: 'pre-regist-info',
          icon: 'fe fe-info font-weight-bold',
          url: PATH.ADMIN.REGISTER_INFO,
        },
        // {
        //   title: '평점안내',
        //   key: 'grade-guide',
        //   icon: 'fe fe-edit-3 font-weight-bold',
        //   url: PATH.ADMIN.GRADE_GUIDE,
        // },
        {
          title: '플레인 페이지',
          key: 'plain-page',
          icon: 'fe fe-file-text font-weight-bold',
          url: PATH.ADMIN.PLAIN,
        },
        {
          title: '게시판',
          key: 'bbs',
          icon: 'fe fe-file-text font-weight-bold',
          url: PATH.ADMIN.BBS_LIST,
        },
        // {
        //   title: '추가 페이지',
        //   key: 'page',
        //   icon: 'budicon budicon-compose font-weight-bold',
        //   url: PATH.ADMIN.PAGE,
        // },
      ],
    },

    // {
    //   category: true,
    //   title: '도구',
    // },
    // {
    //   title: '이메일 검사',
    //   key: 'email-validator',
    //   icon: 'fe fe-mail font-weight-bold',
    //   url: PATH.ADMIN.UTIL_EMAIL_VALIDATOR,
    // },

    {
      category: true,
      title: '관리자',
    },
    {
      title: '로그아웃',
      key: 'logout',
      icon: 'fe fe-log-out font-weight-bold',
      url: PATH.AUTH.LOGOUT,
    },
  ]
}
