import { all, takeEvery, call } from 'redux-saga/effects'
import { notification } from 'antd'
// import { history } from 'App'
// import * as firebase from 'services/firebase'
// import * as firebase from 'services/firebase'
import { rsFirebase } from '../../firebase'
// import { USER, PATH } from 'constant'
import actions from './actions'

export function* DELETE({ payload }) {
  yield call(rsFirebase.firestore.deleteDocument, `bbs/${payload.id}`)

  notification.success({
    message: '삭제 완료.',
    description: '게시판이 삭제되었습니다.',
    duration: 1,
  })
}

export function* ADD({ payload }) {
  const data = {
    ...payload,
    // order: 999999,
  }
  console.log(data)

  yield call(rsFirebase.firestore.addDocument, 'bbs', data)
  notification.success({
    message: '등록 완료.',
    description: '게시판이 등록되었습니다.',
    duration: 1,
  })
}

export function* UPDATE({ payload }) {
  console.log(payload)

  const bbsId = payload.id
  const data = {
    title: payload.title,
    id: payload.id,
    displayDate: payload.displayDate,
    displayAuthor: payload.displayAuthor,
    allowWriteNormalMember: payload.allowWriteNormalMember,
    isPublic: payload.isPublic,
  }

  yield call(rsFirebase.firestore.setDocument, `bbs/${bbsId}`, data, { merge: true })

  notification.success({
    message: '수정 완료.',
    description: '게시판이 수정되었습니다.',
    duration: 1,
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.ADD, ADD),
    takeEvery(actions.DELETE, DELETE),
    takeEvery(actions.UPDATE, UPDATE),
  ])
}
